// import './login.css'
import { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'

export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {login,error,isPending} = useLogin()

    const handleSubmit = (e) => {
        e.preventDefault()
        login(email,password)
    }
    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="mt-4 col-sm-10 col-md-8 col-lg-6 col-xl-5 card p-5 bg-light">
                        <h2>Login</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className='form-label'>Email:</label>
                            <input type="email" className='form-control' required value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                        <div className="mb-3">
                            <label className='form-label'>Password:</label>
                            <input type="password" className='form-control' required value={password} onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        {!isPending && <button className="btn btn-primary">Login</button>}
                        {isPending && <button className="btn btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                        </button>}
                        {error && <div className='alert alert-danger'>{error}</div>}
                    </form>
                </div>
            </div>
        </div>
    )
}
