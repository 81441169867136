// import './navbar.css'
import { Link } from 'react-router-dom'
import { useTheme } from '../hooks/useTheme'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import AdminModal from './AdminModal'
import { useState } from 'react'
import Avatar from './Avatar'
import { useUpdateProfile } from '../hooks/useUpdateProfile'

export default function Navbar() {
    const { color, changeColor } = useTheme()
    const { user, authIsReady} = useAuthContext()
    const { logout, isPending } = useLogout()
    const [showModal,setShowModal] = useState(false)

    const [displayName, setDisplayName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)

    const {updateProfile} = useUpdateProfile()

    const handleSubmit = (e) => {
        let update = {displayName,password,email}
        e.preventDefault()
        console.log(update)
        updateProfile(update)
        setDisplayName("")
        setEmail("")
        setPassword("")
        console.log(e)
    }

    const handleCancel = (e) => {
        console.log(e)
        switch (e) {
            case 'dn':
                setDisplayName('')
                break;
            case 'pw':
                setPassword('')
                break;
            case 'em':
                setEmail('')
                break;
        
            default:
                break;
        }
    }
    return (
        <><nav className="navbar navbar-expand-md navbar-light bg-light">
            <div className="container-xxl">
                <div className="">
                <Link to="/" className="navbar-brand fw-bold text-muted border-end border-3 pe-2">Recipe Book </Link>
                {user && (<span data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample" className='nav-avatar d-sm-inline-block'><Avatar src={user.photoURL}/> <p className='text-muted d-sm-inline-block m-0 avatar-text d-none'>{user.displayName} </p> </span>)}
                {!user && (<Link to="/login" className='text-muted nav-avatar d-sm-inline-block'><i className="bi bi-person-fill"></i> <p className='text-muted d-sm-inline-block d-none m-0'>Not logged in </p> </Link>)}
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-end" id="main-nav">
                    <ul className="navbar-nav nav">
                        {/* {user && (
                            <li className="nav-item mt-2">
                            <span className='nav-avatar d-inline-block d-sm-none'><Avatar src={user.photoURL}/> <p style={{maxWidth:'80px'}}className='text-muted d-inline-block m-0 text-truncate avatar-text'>{user.displayName} </p> </span>
                        </li>
                        )}
                        {!user && (
                            <li className="nav-item mt-2">
                            <span className='nav-avatar d-inline-block d-sm-none'><i className="bi bi-person-fill"></i> <p className='text-muted d-inline-block m-0'>Not logged in </p> </span>
                        </li>
                        )} */}
                        <li className="nav-item">
                            <Link to="/" className="nav-link btn" aria-current="page">Home</Link>
                        </li>
                        {user && (
                            <>
                        <li className="nav-item">
                            <Link to="/create" className="nav-link btn" aria-current="page">Create Recipe</Link>
                        </li>
                        <li className="nav-item">
                            <AdminModal/>
                        </li>
                            </>
                        )}
                        {!user && (
                            <>
                            <li className="nav-item">
                            <Link to="/signup" className="nav-link btn" href="#">Signup</Link>
                            </li>
                            <li className="nav-item">
                            <Link to="/login"className="nav-link btn btn-secondary text-white" href="#">Login</Link>
                            </li>
                            </>
                        )}
                        {user && (
                            <li className="nav-item">
                        {isPending && <Link to="/" type='button' className="nav-link btn btn-outline-secondary" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Logging out...</Link>}
                        
                        {!isPending && <Link to="/" onClick={logout} className="nav-link btn btn-secondary text-white mx-3 my-2 my-md-0">Logout</Link>}
                        </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>

       {user && (<> <div className="offcanvas offcanvas-start" data-bs-backdrop="false" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">Your Profile</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body profile">
          <div className='container-lg'>
              <div className="row justify-content-around mb-4">
                  <div className='col-10 text-center'>
                        <img className="profile-image" src={user.photoURL}/>
                        <h1>{user.displayName}</h1>
                        <small className="text-muted">uid: {user.uid}</small>
                  </div>
              </div>
              <div className="">
                  <div className="card bg-light p-2 mb-2">

            <div className="">
                <span>Name: {user.displayName}   <a className="text-decoration-none float-end text-primary" data-bs-toggle="collapse" href="#nameCollapse" role="button" aria-expanded="false" aria-controls="nameCollapse"><i className="bi bi-pencil-square"></i> Edit</a></span>
                <div className="mb-2 collapse" id="nameCollapse">
                    <div className="card card-body">
                        <form onSubmit={handleSubmit}>
                        <label className='form-label'>Change display name:</label>
                        <input type="text" className='form-control' required value={displayName} onChange={(e) => setDisplayName(e.target.value)}/>
                        <div className="mt-2 text-center">
                            {isPending && <button className="btn btn-primary" disabled>Loading</button>}
                            {!isPending && <button className="btn btn-primary"data-bs-toggle="collapse" href="#nameCollapse">Submit</button>}
                            {!isPending && <button type="reset" className="btn btn-outline-danger ms-2" onClick={() => handleCancel('dn')}>Cancel</button>}
                            {error && <div className='alert alert-warning'>{error}</div>}
                        </div>
                        </form>
                    </div>
                    </div>
            </div>
            </div>
            <div className="card bg-light">
                <div className="card-header">
                    Login details
                </div>
            <div className="card-body">
                <span>Email: {user.email}   <a className="text-decoration-none float-end" data-bs-toggle="collapse" href="#emailCollapse" role="button" aria-expanded="false" aria-controls="emailCollapse"><i className="bi bi-pencil-square"></i> Edit</a></span>
                <div className="mb-2 collapse" id="emailCollapse">
                    <div className="card card-body">
                    <form onSubmit={handleSubmit}>
                        <label className='form-label'>Change email:</label>
                        <input type="text" className='form-control' required value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <div className="mt-2 text-center">
                            {isPending && <button className="btn btn-primary" disabled>Loading</button>}
                            {!isPending && <button className="btn btn-primary">Submit</button>}
                            {!isPending && <button type='reset' className="btn btn-outline-danger ms-2" onClick={() => handleCancel('em')}>Cancel</button>}
                            {error && <div className='alert alert-warning'>{error}</div>}
                        </div>
                        </form>
                    </div>
                    </div>
            <div className="">
                <span>Change password:<a className="float-end text-decoration-none" data-bs-toggle="collapse" href="#passwordCollapse" role="button" aria-expanded="false" aria-controls="passwordCollapse"><i className="bi bi-pencil-square"></i> Edit</a></span>
                <div className="collapse" id="passwordCollapse">
                    <div className="card card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className='form-label'>Old Password:</label>
                            <input type="password" className='form-control' required/>
                        </div>
                        <div className="mb-3">
                            <label className='form-label'>New Password:</label>
                            <input type="password" className='form-control' required value={password} onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div className="mb-3">
                            <label className='form-label'>Confirm New Password:</label>
                            <input type="password" className='form-control' required/>
                        </div>
                        <div className="text-center">
                            {isPending && <button className="btn btn-primary" disabled>Loading</button>}
                            {!isPending && <button className="btn btn-primary">Submit</button>}
                            {!isPending && <button type='reset' className="btn btn-outline-danger ms-2" onClick={() => handleCancel('pw')}>Cancel</button>}
                            {error && <div className='alert alert-warning'>{error}</div>}
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>
            {/* <div className="mb-3">
                <label className='form-label'>Profile picture:</label>
                <input type="file" className='form-control' required onChange={handleFileChange}/>
                {thumbnailError && <div className='alert alert-warning'>{thumbnailError}</div>}
            </div> */}

            </div>
              </div>
          </div>
        </div>
      </div>
</>)}
</>
        // <div className='navbar' style={{background: color}}>
        //     <nav>
        //         <Link to="/" className='brand'>
        //             <h1>MAKBOX Recipe Book</h1>
        //         </Link>
        //         {user && (
            //         <>
            //          <Link className='btn' to="/create">Create Recipe</Link>
        //         </>
        //         )}
        //         {!user && (
        //         <>
        //         <Link className='btn' to="/signup">Signup</Link>
        //         <Link className='btn' to="/login">Login</Link>
        //         </>
        //         )}
        //         {user && (
        //         <>
        //         {isPending && <Link className='btn' to="/" disabled>Logging out...</Link>}
        //         {!isPending && <Link className='btn' to="/" onClick={logout}>Logout</Link>}
        //         </>
        //         )}
        //     </nav>
        // </div>
    )
}
