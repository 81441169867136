import { useState, useEffect } from "react"
import { projectAuth, projectStorage,projectFirestore } from "../config/config"
import { useAuthContext } from "./useAuthContext"

export const useCreate = () => {
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const {dispatch} = useAuthContext()
    const [isCancelled, setIsCancelled] = useState(false)

    const create = async (doc, thumbnail) => {
        setError(null)
        setIsPending(true)

        try {
            const res = await projectFirestore.collection('recipes').add(doc)

            if(!res){
                throw new Error('could not complete submission')
            }

        //upload thumbnail
        const uploadPath = `recipe-images/${res.id}/${thumbnail.name}`
        const img = await projectStorage.ref(uploadPath).put(thumbnail)

        const photoURL = await img.ref.getDownloadURL()

        await res. update({photoURL})

        if(!isCancelled){
            setIsPending(false)
            setError(null)
        }
        } catch (err) {
            if (!isCancelled){
                console.log(err.message)
                setError(err.message)
                setIsPending(false)
            }
        }
    }

    useEffect(() => {
        return ()=> setIsCancelled(true)
    }, [])

    return {error, isPending, create}
}