// import './Admin.css'
import { Link } from 'react-router-dom'
import { useTheme } from '../hooks/useTheme'
import Trashcan from '../assets/trashcan.svg'
import { projectFirestore } from '../config/config'
import { useEffect,useState } from 'react'

export default function ListCategories() {
    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState(false)
    const [categoryList, setCategoryList] = useState('')
    const [category, setCategory] = useState('')
    const {mode} = useTheme()
    
    useEffect(() => {
        setIsPending(true)
        
        const unsub = projectFirestore.collection('recipe_categories').orderBy("label","asc").onSnapshot((doc) => {
            if(doc.empty){
                setError('Could not fetch category list')
                setIsPending(false)
            } else {
                let results = []
                console.log(doc)
                doc.docs.forEach(doc => {
                    results.push({...doc.data(), id:doc.id})
                })
                setCategoryList(results)
                setIsPending(false)
                
            }
        },(err) => {
            setError(err.message)
            setIsPending(false)
        })
        
        return () => unsub()
    },[])
    if(categoryList.length === 0){
        return (<div className='error'>No categories found...</div>)
    }
    const handleClick = (id) => {
        if (window.confirm('Are you sure you wish to delete this category?')){
        projectFirestore.collection('recipe_categories').doc(id).delete()
    }
}

    return (
        <div className='card mt-2'>
            <div>
            {categoryList.map(category => (
                <div key={category.label} className='btn btn-secondary m-1'>
                    <label onClick={() => {handleClick(category.id)}}>{category.label} <i className="bi bi-trash-fill text-white"></i></label>
                </div>
            ))}
            </div>
        </div>
    )
}
