import React from 'react';
import ReactDOM from 'react-dom';
import './css/custom.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap'
import "bootstrap-icons/font/bootstrap-icons.css";
import App from './App';
import { ThemeProvider } from './context/ThemeContext';
import { AuthContextProvider } from './context/AuthContext';
import { AdminContextProvider } from './context/AdminContext';

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <AdminContextProvider>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </AdminContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
