import { Link } from 'react-router-dom'
import { projectFirestore } from '../config/config'
import { useAuthContext } from '../hooks/useAuthContext'
import { useNavigate } from 'react-router'

export default function RecipeList({recipes}) {

    const {user, authIsReady} = useAuthContext()
    const navigate = useNavigate()
    if(recipes.length === 0){
        return (<div className='error'>No recipes found...</div>)
    }

    return (
        <div className='container mt-3'>
            <div className="row g-4 justify-content-around">
            
            {recipes.map(recipe => (
                <div onClick={() => navigate(`/recipes/${recipe.id}`)}key={recipe.id} className="col-xl-4 col-sm-6 d-sm-flex align-content-stretch">
                    <div className='card recipe-card row g-0'>
                        <div className="row ms-0">
                        <div className="col-4 col-sm-12 d-flex p-0">
                            <img className="card-img-top card-img-sm" src={recipe.photoURL ? recipe.photoURL:'https://via.placeholder.com/414x246'}/>
                        </div>
                        <div className="col-8 col-sm-12">
                            <h5 className='card-title mt-2'>{recipe.title}</h5>
                            <p className='text-muted'><i className="bi bi-tag-fill"></i> {recipe.category}</p>
                            {/* <p><i className="bi bi-stopwatch-fill"></i>{recipe.cookingTime}.</p> */}
                            {/* <p><i className="bi bi-hash"></i>makes {recipe.quantity}.</p> */}
                            <p className='card-text mt-2'>{recipe.description.length < 100 ? recipe.description: recipe.description.substring(0,100)+'...'}</p>
                            {/* {user.uid === recipe.user && ( <i className="bi bi-trash-fill recipe-card-utility text-muted" onClick={() => {handleClick(recipe.id)}}></i>)} */}
                            {/* <Link className='btn btn-outline-primary' to={`/recipes/${recipe.id}`}>Cook This</Link> */}
                        </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
        </div>
    )
}
