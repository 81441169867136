import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyDgMAcnUDDCPDLXrkD7WYXEaD41rvsiAN4",
  authDomain: "cooking-ninja-site-16366.firebaseapp.com",
  projectId: "cooking-ninja-site-16366",
  storageBucket: "cooking-ninja-site-16366.appspot.com",
  messagingSenderId: "805274265514",
  appId: "1:805274265514:web:5a7c4a2dd33d64a2f16dd3",
  measurementId: "G-6SHB1EF00Z"
};


  firebase.initializeApp(firebaseConfig)

  const projectFirestore = firebase.firestore()
  const projectAuth = firebase.auth()
  const projectStorage = firebase.storage()
  const analytics = firebase.analytics()

  const timestamp = firebase.firestore.Timestamp

  firebase.firestore().enablePersistence()

  export {projectFirestore, analytics, projectAuth,projectStorage,timestamp}