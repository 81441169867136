import ReactDOM from 'react-dom'
// import './Admin.css'
import { useState } from 'react'
import { projectFirestore } from '../config/config'
import ListCategories from './ListCategories'
import {useTheme} from '../hooks/useTheme'

export default function AdminModal({ children, isSalesModal,setShowModal }) {
  const {mode} = useTheme()
    let [newCategory, setNewCategory] = useState('')
    const handleAdd = async (e) => {
        e.preventDefault()
        let value = newCategory.toLowerCase()
        const label = newCategory[0].toUpperCase() + newCategory.slice(1)
        const doc = {value,label}
        try{
          await projectFirestore.collection('recipe_categories').add(doc)
          setNewCategory('')
          setShowModal(false)
        } catch(err){
          console.log(err)
        }
    }

  return (
   <>

<a type="button" className="nav-link btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Categories
</a>


<div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Admin Settings</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <form>
              <span>Add new recipe category:</span>
                  <div className="input-group mb-3">
                      <input
                          type="text"
                          className='form-control'
                          onChange={(e) => setNewCategory(e.target.value)}
                          required
                          value={newCategory}
                      />
                    <button className="btn btn-primary" onClick={handleAdd}>add</button>
                  </div>
      </form>
 <ListCategories loc='modal'/>
      </div>
      {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>
</>
    // <div className="modal-backdrop">
    //   <div className="modal">
    //         <button className='close' onClick={() => setShowModal(false)}>x</button>
    //   </div>
    // </div>
  )
}