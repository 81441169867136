import RecipeList from '../../components/RecipeList'
import { projectFirestore } from '../../config/config'
import { useEffect } from 'react'
import { useState } from 'react'



export default function Home() {
    const [data, setData] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState(false)
    const [currentFilter, setCurrentFilter] = useState('all')
    const changeFilter = (newFilter) => {setCurrentFilter(newFilter)}
    const [savedDate, setSavedDate] = useState('')

    document.title = "MAKBOX Cookbook"

    // pull data from cache, and then from server if not found
    useEffect(async () => {
        setIsPending(true)
        const unsub = projectFirestore.collection('recipes')
                        .orderBy("createdTime",'desc')
                        .where("deleted","==",false)
                        .get({source:'cache'}).then((task) => {
                            if(task.empty){
                                projectFirestore.collection('recipes')
                                .where("deleted","==",false)
                                .orderBy("createdTime",'desc')
                                .get({source:'server'}).then((task) => {
                                    if(task.empty){
                                        setError('No recipes to load on server')
                                        setIsPending(false)
                                    } else {
                                        let results =[]
                                        task.docs.forEach(doc => {
                                            results.push({id:doc.id,...doc.data()})
                                        })
                                        setData(results)
                                        let source = task.metadata.fromCache ? "local" : "server"
                                        console.log("data from " + source)
                                        setIsPending(false) 
                                    }
                                })
                            } else {
                                let results =[]
                                task.docs.forEach(doc => {
                                            results.push({id:doc.id,...doc.data()})
                                        })
                                        setData(results)
                                        let source = task.metadata.fromCache ? "local" : "server"
                                        console.log("data from " + source)
                                        setIsPending(false) 
                                        // console.log(results[0].createdTime.toDate())
                                        setSavedDate(results[0].createdTime)
                            }
                        },
                            (err) => {
                                    setError(err.message)
                                    console.log(err)
                                    setIsPending(false)
                                })
                                // unsub()
        return () => unsub()
            .catch(err => {
            setError(err.message)
            setIsPending(false)
        })
        },[])

        // pull updated data from the server
        useEffect(async () => {
            console.log(savedDate)
        if(savedDate !== null){

            const unsub = await projectFirestore.collection('recipes')
            .orderBy("createdTime","desc")
            .where("createdTime",">=",savedDate)
                .where("deleted","==",false)
                .get({source:'server'})
                .then((snapshot) => {
                    if(snapshot.empty){
                        console.log('No changes since last snapshot')
                        setIsPending(false)
            } else {
                console.log(snapshot.docs.length +' changes since last snapshot')
                let results =[...data]
                snapshot.docs.forEach(doc => {
                    let remDuplicates = results.filter(i => {
                        return i.id != doc.id;
                    })
                    results = remDuplicates
                    results.unshift({id:doc.id,...doc.data()})
                })
                setData(results)
                setIsPending(false)
                // console.log(data)
                // console.log(results)
            }
        },(err) => {
            setError(err.message)
            console.log(err)
            setIsPending(false)
        })
        // unsub()
        return () => unsub()
        .catch(err => {
            setError(err.message)
            setIsPending(false)
        })
    }
    },[savedDate])

    // // remove deleted recipes from array
    // useEffect(() => {
    //     const recipeData = data
    //   console.log(recipeData)
    
    //   return () => {
    //   };
    // }, [data]);
    
    return (
        <div className='home'>
            {error && <p className='alert alert-danger'>{error}</p>}
            {isPending && <p className='alert alert-info'><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</p>}
            {data && <RecipeList recipes={data}/>}
        </div>
    )
}
