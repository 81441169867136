// import './signup.css'
import { useState } from 'react'
import { useSignup } from '../../hooks/useSignup'

export default function Signup() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [thumbnail, setThumbnail] = useState(null)
    const [thumbnailError, setThumbnailError] = useState(null)
    const {signup,isPending,error} = useSignup()

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(email,password,displayName,thumbnail)
        if(!thumbnailError){
            signup(email, password, displayName, thumbnail)
        }
    }

    const handleFileChange = (e) => {
        setThumbnail(null)
        let selected = e.target.files[0]
        console.log(selected)

        if(!selected){
            setThumbnailError('Please select a file')
            return
        }
        if(!selected.type.includes('image')){
            setThumbnailError('Please select an image file.')
            
            return
        }
        if(selected.size > 500000) {
            setThumbnailError('Image file size must be less than 500kb')
            console.log(thumbnail)
            return
        }

        setThumbnailError(null)
        setThumbnail(selected)
        console.log('Thumbnail updated')
    }
    return (
    <div className="container">
    <div className="row justify-content-center">
        <div className="mt-4 col-sm-10 col-md-8 col-lg-6 col-xl-5 card p-5 bg-light">
            <h2>Sign up</h2>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label className='form-label'>Display name:</label>
                <input type="text" className='form-control' required value={displayName} onChange={(e) => setDisplayName(e.target.value)}/>
            </div>
            <div className="mb-3">
                <label className='form-label'>Email:</label>
                <input type="email" className='form-control' required value={email} onChange={(e) => setEmail(e.target.value)}/>
            </div>
            <div className="mb-3">
                <label className='form-label'>Password:</label>
                <input type="password" className='form-control' required value={password} onChange={(e) => setPassword(e.target.value)}/>
            </div>
            <div className="mb-3">
                <label className='form-label'>Profile picture:</label>
                <input type="file" className='form-control' required onChange={handleFileChange}/>
                {thumbnailError && <div className='alert alert-warning'>{thumbnailError}</div>}
            </div>
            {isPending && <button className="btn btn-primary" disabled>Loading</button>}
            {!isPending && <button className="btn btn-primary">Sign up</button>}
            {error && <div className='alert alert-warning'>{error}</div>}
        </form>
        </div>
        </div>
        </div>
    )
}
