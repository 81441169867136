// import './search.css'
import {useLocation} from 'react-router-dom'
import {useFetch} from '../../hooks/useFetch'
import RecipeList from '../../components/RecipeList'

export default function Search() {
    const queryString = useLocation().search
    const queryPrams = new URLSearchParams(queryString)
    const query = queryPrams.get('q')

    const url = 'http://localhost:3001/recipes?q=' + query
    const {error,isPending, data} = useFetch(url)

    return (
        <div>
            <h2 className="page-title">Recipes including "{query}"</h2>
            {error && <p className='error'>{error}</p>}
            {isPending && <p className='loading'>Loading...</p>}
            {data && <RecipeList recipes={data}/>}
        </div>
    )
}
