// import './recipe.css'
import { useParams,useNavigate,Link } from 'react-router-dom'
import { useEffect,useState } from 'react'
import { projectFirestore } from '../../config/config'
import { useFirestore } from '../../hooks/useFirestore'
import { useAuthContext } from '../../hooks/useAuthContext'
import Avatar from '../../components/Avatar'

export default function Recipe() {
    const {id} = useParams()
    const navigate = useNavigate()
    const {user,authIsReady} = useAuthContext()
    const {softDeleteDocument, response} = useFirestore('recipes')

    const [recipe, setRecipe] = useState(null)
    const [recipeAuthor, setRecipeAuthor] = useState('')
    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState(false)

    // useEffect(() => {
    //     if(error){
    //         setTimeout(() => {
    //             navigate('/')
    //         }, 3000);
    //     }
    // }, [error, navigate])

    useEffect(() =>  {
        setIsPending(true)
        
        const unsub = projectFirestore.collection('recipes').doc(id).onSnapshot((doc) => {
            if(doc.exists){
                setIsPending(false)
                setRecipe(doc.data())
                document.title = doc.title
            } else {
                setIsPending(false)
                setError('Could not find that recipe')
            }
        })
        
            return () => unsub()
    },[id])

    useEffect(() => {
        if(recipe !== null){
      document.title = recipe.title
        }
      return () => {
      }
    }, [recipe])
    

    useEffect(() => {
      setIsPending(true);
    
      if(recipe !== null){

          const unsub = projectFirestore.collection('users').doc(recipe.createdBy).onSnapshot((doc) => {
              if(doc.exists){
                  setIsPending(false)
                  setRecipeAuthor(doc.data())
                //   console.log(recipeAuthor)
                } else {
                    setIsPending(false)
                    setError('Could not find author')
                }
            })
            
            return () => unsub()
        }
    }, [recipe]);
    
    const handleDelete = (id) => {
        if (window.confirm('Are you sure you wish to delete this recipe?')){
            softDeleteDocument(id)
            navigate('/')
        }
    }
    return (

        <div>
            {isPending && <div className='alert alert-info'><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...</div>}
            {error && <div className='alert alert-danger'>{error}</div>}
            {recipe && (
                <>
                <div className="container-lg py-2 my-3">
                    <div className="row align-items-center g-5">
                        <div className="col-md-6">
                            <div className="recipe-image">
                                <div className="recipe-image-badges">
                                    <span></span>
                                    <span></span>
                                </div>
                                <img src={recipe.photoURL ? recipe.photoURL:'https://via.placeholder.com/414x246'} className='img-fluid'/>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className='bg-white bg-opacity-50 p-2 mb-2 rounded'>
                            <h1>{recipe.title}</h1>
                            <div className='d-flex'>
                                <small className="text-muted recipe-header-avatar">by {recipeAuthor.displayName} on {recipe.createdTime.toDate().toDateString()} <Avatar src={recipeAuthor.photoURL}/></small>
                            </div>
                            </div>
                            <p className="lead bg-white bg-opacity-50 p-2 rounded">{recipe.description}</p>
                            <h4>
                                <span className="badge bg-primary text-white m-1">
                                <i className="bi bi-clock me-2 d-inline-block"></i> {recipe.cookingTime}
                                </span>
                                <span className="badge bg-primary text-white m-1">
                                <i className="bi bi-people-fill"></i> {recipe.quantity}
                                </span>
                                {/* <span className="dropdown m-1">
                                    <span className="badge bg-primary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi bi-people-fill"></i> {recipe.quantity}
                                    </span>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </span> */}
                            </h4>
                            <div className="my-2 bg-white bg-opacity-50 p-2 rounded">
                                <p className='m-0'>Jump to: <a href="#ingredients">Ingredients</a> | <a href="#method">Method</a> 
                                {user && (<> | <a href='#admin'>Admin</a></>)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <section id="ingredients" className="bg-light">
                    <div className="container-lg mb-5 py-5">
                        <div className="row g-5 justify-content-around">
                            <div className="col-md-4">
                                <div className="card bg-secondary text-white mb-3">
                                    <h5 className="card-header">Ingredients</h5>
                                    <ul className="list-group list-group-flush">
                                    {recipe.ingredients.map(ing => <li key={ing} className='list-group-item bg-secondary-light text-secondary'>{ing}</li>)}
                                    </ul>
                                </div>
                            </div>
                            <div id="method" className="col-md-7">
                                <h4>Method:</h4>
                                <ul className='list-group'>
                                <p className='text-muted'>{recipe.methodArray.map(m => <label className='list-group-item' key={m}><input className="form-check-input me-2" type="checkbox" value="" aria-label="..."></input>{m}</label>)}</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="admin" className="pb-4">
                            <div className=" rounded text-center">
                                {/* <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magnam numquam error cum, nisi ad officiis eius blanditiis delectus minus voluptate.</p> */}
                                {user && (<Link className='btn btn-outline-primary' to={`/edit/${id}`}>Edit Recipe</Link>)}
                                {user && user.uid === recipe.createdBy && ( <button className="btn btn-danger ms-2" onClick={() => {handleDelete(id)}}>Delete Recipe</button>)}
                            </div>
                </section>
                </>
            )}
        </div>
    )
}
