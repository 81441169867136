import { BrowserRouter, Routes, Route,Navigate } from 'react-router-dom'

import Home from './pages/home/home'
import Create from './pages/create/create'
import Search from './pages/search/search'
import Recipe from './pages/recipe/recipe'
import Edit from './pages/edit/edit'

import Navbar from './components/navbar'
import ThemeSelector from './components/ThemeSelector'
import { useTheme } from './hooks/useTheme'
import { useAuthContext } from './hooks/useAuthContext'
import Login from './pages/login/Login'
import Signup from './pages/signup/Signup'
import bg from './assets/foodbg.jpg'


function App() {
  const {mode} = useTheme()
  const {authIsReady,user}= useAuthContext()


  return (
    <div className="App" style={{background:`url(${bg})`, backgroundSize:'cover', backgroundAttachment:'fixed'}}>
      {authIsReady && (
      <BrowserRouter>
        <Navbar/>
        {/* <ThemeSelector /> */}
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/signup" element={user ? <Navigate to="/"/>:<Signup/>}/>
          <Route path="/login" element={user ? <Navigate to="/"/>:<Login/>}/>
          <Route path="/create" element={!user ? <Navigate to="/login"/>:<Create/>}/>
          <Route path="/search" element={<Search/>}/>
          <Route path="/recipes/:id" element={<Recipe/>}/>
          <Route path="/edit/:id" element={!user ? <Navigate to="/"/>:<Edit/>}/>
        </Routes>
      </BrowserRouter>
      )}

      
    </div>
  );
}

export default App
