// import './create.css'
import { useState,useRef,useEffect } from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import { projectFirestore } from '../../config/config'
import Select from 'react-select'
import { useCreate } from '../../hooks/useCreate'
import { AuthContext } from '../../context/AuthContext'
import { useAuthContext } from '../../hooks/useAuthContext'
import Recipe from '../recipe/recipe'
import { useFirestore } from '../../hooks/useFirestore'
import RecipeForm from '../../components/RecipeForm'

export default function Edit() {
const {updateDocument, response} = useFirestore('recipes')
const [title, setTitle] = useState('')
const [method, setMethod] = useState('')
const [cookingTime, setCookingTime] = useState('')
const [newIngredient, setNewIngredient] = useState('')
const [ingredients, setIngredients] = useState([])
const [categoryList, setCategoryList] = useState('')
const [category, setCategory] = useState('')
const ingredientInput = useRef(null)
const navigate = useNavigate()
const [isPending, setIsPending] = useState(false)
const [error, setError] = useState(false)
const [quantity, setQuantity] = useState('')
const [thumbnail, setThumbnail] = useState(null)
const [thumbnailError, setThumbnailError] = useState(null)
const {create} = useCreate()
const {user,authIsReady} = useAuthContext()
const [description, setDescription] = useState('')
const [methodArray, setMethodArray] = useState([])
const {id} = useParams()
const [recipe, setRecipe] = useState(null)
const [newImage, setNewImage] = useState(false)

useEffect(async () => {
    setIsPending(true)
    
    const unsub = projectFirestore.collection('recipes').doc(id).onSnapshot((doc) => {
        if (doc.exists) {
            setIsPending(false)
            setRecipe(doc.data())
        } else {
            setIsPending(false)
            setError('Could not find that recipe')
        }
    })
    return () => unsub()
},[id])

useEffect(() => {
    if(recipe){
        setTitle(recipe.title)
        setDescription(recipe.description)
        setCategory(recipe.category)
        setQuantity(recipe.quantity)
        setIngredients(recipe.ingredients)
        setMethodArray(recipe.methodArray)
        setCookingTime(recipe.cookingTime)
        setThumbnail(recipe.photoURL)
        setMethod(recipe.methodArray.join("\n"))
    }
}, [recipe]);

const handleSubmit = async (e) => {
    e.preventDefault()
    const createdBy = {
        displayName: user.displayName,
        photoURL: user.photoURL,
        id:user.uid
    }
    const updates = {
        title,
        description,
        category,
        quantity,
        ingredients,
        methodArray,
        cookingTime,
        createdBy
    }
    await updateDocument(id, 
        updates
    )
    if(!response.error){
        navigate(`/recipes/${id}`)
    }
}
// const handleSubmit = async (e) => {
//     e.preventDefault()
//     if(!thumbnail){
//         if(!window.confirm('You have not selected an image for the recipe. A default will be used.')){
//             setError('Please select an image')
//             return
//         }
//     }
//     setError(null)
//     const doc = {title,description,category,quantity,ingredients,methodArray,cookingTime: cookingTime +" minutes",user:user.uid}
//     try{
//         console.log(user.uid)
//         create(doc,thumbnail)
//         navigate('/')
//     } catch(err){
//         console.log(err)
//     }
// }

const handleFileChange = (e) => {
    setNewImage(false)
    setThumbnail(null)
    let selected = e.target.files[0]
    console.log(selected)

    if(!selected){
        setThumbnailError('Please select a file')
        return
    }
    if(!selected.type.includes('image')){
        setThumbnailError('Please select an image file.')
        
        return
    }
    if(selected.size > 1000000) {
        setThumbnailError('Image file size must be less than 1Mb.')
        console.log(thumbnail)
        return
    }

    setThumbnailError(null)
    setThumbnail(selected)
    setNewImage(true)
    console.log('Thumbnail updated')
}

const splitMethod = (e) => {
    let methodArray = e.split("\n")
    console.log(methodArray)
    setMethod(e)
    setMethodArray(methodArray)
}
useEffect(() => {
    setIsPending(true)
    
    const unsub = projectFirestore.collection('recipe_categories').onSnapshot((doc) => {
        if(doc.empty){
            setError('Could not fetch category list')
            setIsPending(false)
        } else {
            let results = []
            doc.docs.forEach(doc => {
                results.push({...doc.data()})
            })
            setCategoryList(results)
            setIsPending(false)
            
        }
    },(err) => {
        setError(err.message)
        setIsPending(false)
    })
    
    return () => unsub()
},[])

// still needs sorting
const handleRemove = (e) => {
    const str = e.target.innerHTML.substring(0,e.target.innerHTML.length)
    const index = ingredients.indexOf(str)
    if (index > -1) {
        ingredients.splice(index, 1)
    }
    setIngredients(prevIngredients => [...ingredients])
}

const handleAdd = (e) => {
    e.preventDefault()
    const ing = newIngredient.trim()

    if(ing && !ingredients.includes(ing)){
        setIngredients(prevIngredients => [...prevIngredients, ing])
    }
    setNewIngredient('')
    ingredientInput.current.focus()
}

const categories = categoryList

    return (
        <RecipeForm title="Update a Recipe" mode="edit"/>
        // <div className="container">
        //     <div className="row justify-content-center">
        //         <div className="mt-4 col-sm-10 col-md-8 col-lg-6 card p-5 bg-light">
        //     <h2 className="page-title">
        //         Update a Recipe
        //     </h2>

        //     <form onSubmit={handleSubmit}>
        //         <div className="mb-3">
        //             <label className='form-label'>Recipe name:</label>
        //             <input className='form-control'
        //                 type="text" 
        //                 onChange={(e) => setTitle(e.target.value)}
        //                 value={title}
        //                 required
        //             />
        //         </div>
        //         <div className="mb-3">
        //             <label className='form-label'>Recipe description:</label>
        //             <textarea className='form-control'
        //             rows={5}
        //                 onChange={(e) => setDescription(e.target.value)}
        //                 value={description}
        //                 required
        //             />
        //         </div>
        //         <div className="mb-3">
        //             <label className='form-label'>Category:</label>
        //             <Select
        //                 onChange={(option) => setCategory(option.label)}
        //                 options={categories}
        //                 value={{label: category, value: category}}
        //             />
        //         </div>

        //         <div className="mb-3">
        //             <label className='form-label'>Makes:</label>
        //             <input className='form-control'
        //                 type="text" 
        //                 onChange={(e) => setQuantity(e.target.value)}
        //                 value={quantity}
        //                 required
        //             />
        //         </div>

        //         <div className="mb-3">
        //             <label className='form-label'>Recipe Ingredients:</label>
        //                 <div className='input-group'>
        //                     <input className='form-control'
        //                         type="text"
        //                         onChange={(e) => setNewIngredient(e.target.value)} 
        //                         value={newIngredient}
        //                         ref={ingredientInput}
        //                         placeholder='item'
        //                     />
        //                     <button onClick={handleAdd} className="btn btn-primary">add</button>
        //                 </div>
        //         </div>
        //         <p>Current ingredients (click to remove):</p>
        //         <ul className='list-group list-hover mb-3'>
        //             {ingredients.map(i => <button className="text-align-start list-group-item list-group-item-action" onClick={(e) => handleRemove(e)} name={i} key={i}>{i}</button>)}
        //         </ul> 
        //         <div className="mb-3">
        //             <label className='form-label'>Recipe method:</label>
        //             <textarea className='form-control'
        //                 rows={10}
        //                 onChange={(e) => splitMethod(e.target.value)}
        //                 value={method}
        //                 required
        //                 id="method"
        //             />
        //         </div>
        //         <div className="mb-3">
        //             <label className='form-label'>Cooking time:</label>
        //             <input className='form-control'
        //                 onChange={(e) => setCookingTime(e.target.value)}
        //                 value={cookingTime}
        //                 required
        //             />
        //         </div>
        //         {/* <div className="mb-3">
        //             <label className='form-label'>Change Recipe image:</label>
        //             <input type="file" className='form-control mb-1' onChange={handleFileChange}/>
        //             {!newImage && thumbnail && <img className='img-thumbnail'src={recipe.photoURL}/>}
        //             {thumbnailError && <div className='alert alert-danger'>{thumbnailError}</div>}
        //         </div> */}
        //         <div className="text-center">
                
        //         {isPending && <button className="btn btn-primary" disabled>Loading</button>}
        //         {!isPending && <button className="btn btn-primary">Submit</button>}
        //         <button onClick={() => navigate(`/recipes/${id}`)}className="btn btn-outline-danger ms-2">Cancel</button>
        //         {error && <div className='alert alert-danger'>{error}</div>}
        //         </div>
        //     </form>
        // </div>
        // </div>
        // </div>
    )
}
