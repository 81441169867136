import { useAuthContext } from "./useAuthContext"
import { useState, useEffect } from "react"
import { projectAuth, projectStorage,projectFirestore } from "../config/config"

export const useUpdateProfile = () => {
    const [error, setError] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const {user} = useAuthContext()

    // const promptForCredentials = () => {
        
    // }

    const updateProfile = async (updates) => {
        console.log(updates)
        setError(null)
        setIsPending(true)

            await user.updateProfile({
                //dn and photo
              displayName: updates.displayName,
              email: updates.email
            }).then(() => {
                console.log('updating profile');
                setIsPending(false)
            }).catch((error) => {
                console.log(error)
            })

            // const credential = promptForCredentials();
            
            // await user.updateEmail(
            //     updates.email
            // ).then(() => {
            //     console.log('updating email')
            // }).catch((err) => {
            //     console.log(err)
            // })
            
            await projectFirestore.collection('users').doc(user.uid).update({
                displayName: updates.displayName,
                // photoURL: updates.photoURL
            })
      }
  return {updateProfile, error, isPending};
};
